import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import _ from 'lodash';

import { Content, MenuPage } from 'src/modules/layout';
import { margin, colors } from 'src/modules/styles';
import { Image } from 'src/modules/image';
import { Title } from 'src/modules/text';
import { SEO, slugify } from 'src/modules/seo';
import { FullscreenStateContext } from 'src/modules/mouse';

const S = {
  HeroImage: styled(Image)`
    ${margin.bottom.xl}
    max-width: 100%;
    max-height: 70vh;
  `,
  Content: styled(Content)`
    ${margin.bottom.m}
  `,
  Gallery: styled.div`
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &::-webkit-scrollbar {
      margin-top: 10px;
      height: 5px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.black};
      display: block;
    }
  `,
  Image: styled.div`
    margin-right: 10px;
    height: 400px;
  `,
  FullscreenImage: styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  `,
  Fullscreen: styled.div`
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
  `,
  Close: styled.div`
    position: absolute;
    font-size: 30px;
    color: white;
    right: 10px;
    top: 10px;
  `,
};

const getStateType = _.property('state.type');

const ServicePage = ({ transitionStatus, data, pageContext, entry, exit }) => {
  const { setIsInFullscreenState } = useContext(FullscreenStateContext);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const { markdownRemark: post } = data;
  const { metaDescription, pages } = post.frontmatter;
  const { langKey } = post.fields;
  const currentPage = pages.find(({ title }) => slugify(title) === pageContext.slug);

  return (
    <>
      {fullscreenImage !== null && (
        <S.Fullscreen>
          {currentPage.fullscreenImages.map(({ image }, index) =>
            fullscreenImage === index ? (
              <S.FullscreenImage>
                <Image
                  data={image}
                  style={{ width: '100%', height: '100%' }}
                  imgStyle={{ objectFit: 'contain', width: '100%', height: '100%' }}
                />
              </S.FullscreenImage>
            ) : null,
          )}
          <S.Close
            onClick={() => {
              setFullscreenImage(null);
              setIsInFullscreenState(false);
            }}
          >
            X
          </S.Close>
        </S.Fullscreen>
      )}
      <MenuPage
        lang={langKey}
        routeRoot="services"
        items={pages}
        currentItem={currentPage}
        transition={{ status: transitionStatus, type: getStateType(entry) || getStateType(exit) }}
      >
        <SEO title={currentPage.title} description={metaDescription} />
        <Title>{currentPage.title}</Title>
        <S.Content content={currentPage.description} />
        {!_.isEmpty(currentPage.images) && (
          <S.Gallery>
            {currentPage.images.map(({ image }, index) => (
              <S.Image
                onClick={() => {
                  setFullscreenImage(index);
                  setIsInFullscreenState(true);
                }}
              >
                <Image data={image} imgStyle={{ objectFit: 'contain', height: 400 }} />
              </S.Image>
            ))}
          </S.Gallery>
        )}
      </MenuPage>
    </>
  );
};

ServicePage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  entry: PropTypes.shape({
    state: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  exit: PropTypes.shape({
    state: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pages: PropTypes.arrayOf({
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  transitionStatus: PropTypes.string.isRequired,
};

ServicePage.defaultProps = {
  entry: null,
  exit: null,
};

export default ServicePage;

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        metaTitle
        metaDescription
        pages {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            image {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          fullscreenImages: images {
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
        }
      }
    }
  }
`;
